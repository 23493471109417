import config from "./config";

export default {
  styleOverrides: {
    tooltip: {
      backgroundColor: config.colors.base.black,
      borderRadius: config.radius.sm,
    },
    arrow: {
      color: config.colors.base.black,
    },
  },
};
