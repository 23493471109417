import { Box, type ButtonProps, CircularProgress, Button as MuiButton } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

/**
 * Types
 */
interface Props extends ButtonProps {
  loading?: boolean;
}

/**
 * Styles
 */
const useStyles = makeStyles()({
  loading: {
    position: "relative",
  },
  progress: {
    position: "absolute",
  },
  content: {
    visibility: "hidden",
  },
  fullWidth: {
    width: "100%",
  },
});

/**
 * Constants
 */
const CIRCULAR_PROGRESS_SIZE = 20;

const Button: React.FC<Props> = ({
  loading,
  children,
  disabled,
  variant,
  className,
  disableRipple,
  fullWidth,
  ...props
}: Props) => {
  const { classes, cx } = useStyles();
  const color = variant === "contained" ? "white" : "primary";

  return (
    <MuiButton
      {...props}
      variant={variant}
      fullWidth={fullWidth}
      disabled={loading || disabled}
      disableRipple={disableRipple ?? variant === "text"}
      className={cx({ [classes.loading]: loading }, className)}
    >
      {loading ? (
        <CircularProgress
          sx={{
            color,
          }}
          size={CIRCULAR_PROGRESS_SIZE}
          className={classes.progress}
        />
      ) : null}
      <Box className={cx({ [classes.content]: loading, [classes.fullWidth]: fullWidth })}>
        {children}
      </Box>
    </MuiButton>
  );
};

export default Button;
