import React, { type ReactNode } from "react";

import { useSetState } from "@hey-lady/shared/hooks/general";
import {
  ArticleByAuthor,
  ArticleCategory,
  AvailabilityStatus,
} from "@hey-lady/shared/types/graphql";
import { Filters } from "../components/Filters/consts";

/**
 * Types
 */
interface Props {
  children: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
}

export interface ContextType {
  filters: Filters;
  setFilters: (filters: Partial<Filters>) => void;
}

/**
 * Context
 */
const FiltersContext: React.Context<ContextType> = React.createContext({} as ContextType);

/**
 * Provider
 */
export const FiltersProvider: React.FC<Props> = ({ children }: Props) => {
  // TODO onSetFilters we should run a callback function to update pagination
  const [filters, setFilters] = useSetState<Filters>({
    articles: {
      category: ArticleCategory.Any,
      author: ArticleByAuthor.Any,
    },
    people: {
      availability: [AvailabilityStatus.Shared],
      membership: [],
      location: [],
      interests: [],
      englishSkills: [],
      englishLevels: [],
      occupations: [],
    },
  });

  return (
    <FiltersContext.Provider
      value={{
        filters,
        setFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersContext;
