import type { FallbackRender } from "@sentry/react";

// This Outer component doesn't have any provider available.
// Serves only as a mechanism to catch errors in the application setup
export const OuterErrorFallback: FallbackRender = () => {
  return (
    <div>
      <h1>An unexpected error happened</h1>
      <p>
        <strong>Please reload this page</strong>
      </p>
      <p>
        If the error persists please <a href="mailto:hello@heylady.io">contact support</a> or try
        again later
      </p>
    </div>
  );
};
