import config from "./config";

export default {
  styleOverrides: {
    colorPrimary: {
      fill: config.colors.green.pea,
    },
    colorSecondary: {
      fill: config.colors.gray.silver,
    },
    colorAction: {
      fill: config.colors.white,
    },
  },
};
