import config from "./config";

export default {
  styleOverrides: {
    root: {
      padding: "unset",
      color: config.colors.base.black,
      marginRight: config.spacing.sm,
    },
    colorSecondary: {
      "&.Mui-checked": {
        color: config.colors.green.pea,
      },
    },
  },
};
