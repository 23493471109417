import config from "./config";

export default {
  styleOverrides: {
    root: {
      height: "24px",
      borderRadius: config.radius.l,
    },
    bar: {
      borderRadius: config.radius.l,
    },
    colorPrimary: {
      backgroundColor: config.colors.green.light,
    },
    barColorPrimary: {
      backgroundColor: config.colors.green.jet,
    },
    colorSecondary: {
      backgroundColor: config.colors.beige.bianca,
    },
    barColorSecondary: {
      backgroundColor: config.colors.beige.almond,
    },
  },
};
