import React, { type ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { DefaultPageParam } from "@hey-lady/shared/helpers/const";
import { EventFilters } from "../components/Filters/consts";
import { getEventFiltersSearchParams } from "../components/Filters/helpers";
import { useGetEventFiltersFromUrl } from "../components/Filters/hooks";

/**
 * Types
 */
interface Props {
  children: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
}

export interface ContextType {
  filters: EventFilters;
  setFilters: (filters: Partial<EventFilters>) => void;
  searchParams: string;
}

/**
 * Context
 */
const EventsFiltersContext: React.Context<ContextType> = React.createContext({} as ContextType);

/**
 * Provider
 */
export const EventsFiltersProvider: React.FC<Props> = ({ children }: Props) => {
  const defaultEventFilters = useGetEventFiltersFromUrl();

  const filters = {
    events: defaultEventFilters,
  };

  const [currentSearchParams] = useSearchParams();
  const searchParams = getEventFiltersSearchParams(currentSearchParams, filters);

  const navigate = useNavigate();
  const setFilters = (filters: EventFilters) => {
    const searchParams = getEventFiltersSearchParams(new URLSearchParams(), filters);

    navigate({ pathname: `/events/all/${DefaultPageParam}`, search: searchParams });
  };

  return (
    <EventsFiltersContext.Provider
      value={{
        filters,
        setFilters,
        searchParams,
      }}
    >
      {children}
    </EventsFiltersContext.Provider>
  );
};

export default EventsFiltersContext;
