import config from "./config";

export default {
  styleOverrides: {
    root: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: config.colors.gray.smoke,
      backgroundColor: config.colors.white,
      borderRadius: config.radius.sm,
      "&.Mui-error": {
        borderColor: config.colors.red.monza,
      },
    },
    input: {
      height: "auto",
      color: config.colors.base.black,
      lineHeight: config.typography.line.normal,
      fontSize: config.typography.size.normal,
      fontWeight: config.typography.weight.regular,
      paddingLeft: config.spacing.ml,
      paddingRight: config.spacing.ml,
      paddingTop: config.spacing.ml,
      paddingBottom: config.spacing.ml,
      borderRadius: config.radius.sm,
      "[type=number]": {
        MozAppearance: "textfield",
      },
      "&::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: "0",
      },
      "&::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: "0",
      },
    },
    formControl: {
      "label + &": {
        marginTop: "unset",
      },
    },
    sizeSmall: {
      "& input": {
        paddingTop: config.spacing.sm,
        paddingBottom: config.spacing.sm,
      },
      "& textarea": {
        paddingTop: config.spacing.xs,
        paddingBottom: config.spacing.xs,
      },
    },
  },
  defaultProps: {
    rows: 5,
    disableUnderline: true,
  },
};
