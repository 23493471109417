import config from "./config";

export default {
  styleOverrides: {
    root: {
      color: config.colors.base.black,
    },
    subtitle1: {
      fontSize: config.typography.size.title,
      fontFamily: config.typography.family.serif,
      fontWeight: config.typography.weight.regular,
      lineHeight: config.typography.line.title,
      [config.breakpoints.down("sm")]: {
        fontSize: config.typography.size.subtitle,
        lineHeight: config.typography.line.subheading,
      },
    },
    subtitle2: {
      fontSize: config.typography.size.heading,
      fontFamily: config.typography.family.serif,
      fontWeight: config.typography.weight.regular,
      lineHeight: config.typography.line.subheading,
      [config.breakpoints.down("sm")]: {
        fontSize: config.typography.size.subheading,
        lineHeight: config.typography.line.big,
      },
    },
    h1: {
      fontSize: config.typography.size.subtitle,
      fontWeight: config.typography.weight.semibold,
      lineHeight: config.typography.line.title,
      [config.breakpoints.down("md")]: {
        lineHeight: config.typography.line.heading,
      },
      [config.breakpoints.down("sm")]: {
        fontSize: config.typography.size.heading,
        lineHeight: config.typography.line.big,
      },
    },
    h2: {
      fontSize: config.typography.size.subtitle,
      fontWeight: config.typography.weight.regular,
      lineHeight: config.typography.line.subtitle,
      [config.breakpoints.down("sm")]: {
        fontSize: config.typography.size.heading,
        lineHeight: config.typography.line.subheading,
      },
    },
    h3: {
      fontSize: config.typography.size.heading,
      fontWeight: config.typography.weight.regular,
      lineHeight: config.typography.line.heading,
      [config.breakpoints.down("sm")]: {
        fontSize: config.typography.size.subheading,
        lineHeight: config.typography.line.big,
      },
    },
    h4: {
      fontSize: config.typography.size.subheading,
      fontWeight: config.typography.weight.semibold,
      lineHeight: config.typography.line.big,
      [config.breakpoints.down("sm")]: {
        fontSize: config.typography.size.subheading,
        lineHeight: config.typography.line.big,
      },
    },
    h5: {
      fontSize: config.typography.size.subheading,
      fontWeight: config.typography.weight.regular,
      lineHeight: config.typography.line.big,
      [config.breakpoints.down("sm")]: {
        fontSize: config.typography.size.big,
        lineHeight: config.typography.line.paragraph,
      },
    },
    h6: {
      fontSize: config.typography.size.big,
      fontWeight: config.typography.weight.light,
      lineHeight: config.typography.line.normal,
      [config.breakpoints.down("sm")]: {
        fontSize: config.typography.size.big,
        lineHeight: config.typography.line.paragraph,
      },
    },
    body1: {
      fontSize: config.typography.size.normal,
      fontWeight: config.typography.weight.regular,
      lineHeight: config.typography.line.normal,
    },
    body2: {
      fontSize: config.typography.size.normal,
      fontWeight: config.typography.weight.light,
      lineHeight: config.typography.line.normal,
    },
    caption: {
      fontSize: config.typography.size.small,
      fontWeight: config.typography.weight.light,
      lineHeight: config.typography.line.small,
    },
  },
  defaultProps: {
    variantMapping: {
      h1: "h1",
      h2: "h2",
      h3: "h3",
      h4: "h4",
      h5: "h5",
      h6: "h6",
      subtitle1: "h1",
      subtitle2: "h2",
      body1: "p",
      body2: "p",
      caption: "small",
    },
  },
};
