import config from "./config";

export default {
  styleOverrides: {
    select: {
      "&:focus": {
        backgroundColor: "unset",
      },
    },
    icon: {
      right: config.spacing.sm,
      color: config.colors.base.black,
    },
    outlined: {
      paddingLeft: config.spacing.l,
      paddingRight: config.spacing.xxl,
      fontSize: config.typography.size.small,
      borderRadius: config.radius.l,
      backgroundColor: config.colors.white,
      lineHeight: config.typography.line.small,
      border: `1px solid ${config.colors.orange.brandy}`,
      "& fieldset": {
        border: "none",
      },
      "&.MuiSelect-outlined": {
        paddingLeft: config.spacing.l,
        paddingRight: config.spacing.xxl,
      },
      "&:focus": {
        borderRadius: config.radius.l,
        backgroundColor: config.colors.white,
      },
    },
    standard: {
      borderColor: config.colors.gray.smoke,
    },
    iconOutlined: {
      right: config.spacing.ml,
    },
  },
};
