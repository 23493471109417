import config from "./config";

export default {
  styleOverrides: {
    root: {
      border: "unset",
      backgroundColor: "unset",
      padding: config.spacing.s,
      marginTop: -config.spacing.s,
      marginLeft: -config.spacing.s,
      marginBottom: -config.spacing.s,
      color: config.colors.gray.silver,
    },
    colorPrimary: {
      ".Mui-checked": {
        color: config.colors.green.pea,
      },
    },
  },
};
