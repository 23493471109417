import * as Sentry from "@sentry/react";
import React from "react";
import Apollo from "./Apollo";
import IntlProviderWrapper from "./Intl";
import { OuterErrorFallback } from "./OuterErrorFallback";
import QueryProvider from "./QueryProvider";
import Router from "./Router";
import Snackbar from "./Snackbar";
import Theme from "./Theme";

const Root: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={OuterErrorFallback}>
      <IntlProviderWrapper>
        <Theme>
          <Snackbar>
            <QueryProvider>
              <Apollo>
                <Router />
              </Apollo>
            </QueryProvider>
          </Snackbar>
        </Theme>
      </IntlProviderWrapper>
    </Sentry.ErrorBoundary>
  );
};

export default Root;
