import type { FieldPolicy } from "@apollo/client";

export const userHostedEventsFieldsPolicy: Record<string, FieldPolicy<any>> = {
  userHostedEvents: {
    keyArgs: ["filters", []],
    merge(existing, incoming, { args }) {
      const incomingOffset = args?.filters?.offset || 0;

      // If it's the first page or filter changed we should return incoming
      if (!existing || incomingOffset === 0) {
        return incoming;
      }

      const mergedItems = [...(existing?.items ?? [])];
      const existingItemKeys = existing?.items.map((item: any) => item.__ref) ?? [];
      for (let i = 0; i < incoming.items.length; i++) {
        if (!existingItemKeys.includes(incoming.items[i].__ref)) {
          mergedItems.push(incoming.items[i]);
        }
      }
      return {
        ...incoming,
        items: mergedItems,
      };
    },
  },
};
