import React from "react";
import { IntlProvider } from "react-intl";

import copy from "@hey-lady/shared/copy";

/**
 * Types
 */
interface Props {
  children: JSX.Element[] | JSX.Element;
}

/**
 * TODO when we have translations this locale will need to either come
 * from users navigator or our own store / api
 */
const locale = "en-AU";

/**
 * TODO log error on Sentry and/or
 * send alerts to Slack.
 */
const onError = (error: Error): void => {
  console.error(error);
};

const IntlProviderWrapper: React.FC<Props> = ({ children }: Props) => {
  return (
    <IntlProvider locale={locale} messages={copy[locale]} onError={onError}>
      {children}
    </IntlProvider>
  );
};

export default IntlProviderWrapper;
