import config from "./config";

export default {
  styleOverrides: {
    maxWidthXs: {
      "@media (min-width: 0px)": {
        maxWidth: config.size.xs,
      },
    },
    maxWidthSm: {
      "@media (min-width: 0px)": {
        maxWidth: config.size.sm,
      },
      [config.breakpoints.down("md")]: {
        width: "100%",
        paddingLeft: config.spacing.xxl,
        paddingRight: config.spacing.xxl,
      },
      [config.breakpoints.down("sm")]: {
        paddingLeft: config.spacing.l,
        paddingRight: config.spacing.l,
      },
    },
    maxWidthMd: {
      "@media (min-width: 0px)": {
        maxWidth: config.size.md,
      },
    },
  },
  defaultProps: {
    disableGutters: true,
  },
};
