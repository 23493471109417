import config from "./config";

export default {
  styleOverrides: {
    body: {
      color: config.colors.base.black,
      fontSize: config.typography.size.normal,
    },
    a: {
      color: "unset",
      textDecoration: "none",
      "&:active": {
        color: "unset",
      },
    },
    "b, strong": {
      fontWeight: config.typography.weight.regular,
    },
  },
};
