import { IconButton, styled } from "@mui/material";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import React, { useRef } from "react";

import IconCross from "@hey-lady/ui/icons/IconCross";
import config from "../../ui/overrides/config";

/**
 * Types
 */
interface Props {
  children: JSX.Element | JSX.Element[];
}

/**
 * Styles
 */
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    color: config.colors.green.pea,
    backgroundColor: config.colors.green.swans,
    "& path": {
      color: config.colors.green.pea,
    },
  },
  "&.notistack-MuiContent-error": {
    color: config.colors.red.grenadier,
    backgroundColor: config.colors.pink.peach,
    "& path": {
      color: config.colors.red.grenadier,
    },
  },
}));

const Snackbar: React.FC<Props> = ({ children }: Props) => {
  const snackbarRef = useRef<any>(null);

  const renderers = {
    action: (key: string) => {
      return (
        <IconButton disableRipple onClick={() => snackbarRef?.current?.closeSnackbar(key)}>
          <IconCross size="xs" />
        </IconButton>
      );
    },
  };

  return (
    <SnackbarProvider
      ref={snackbarRef}
      action={renderers.action}
      hideIconVariant
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default Snackbar;
