import loadingAnimation from "@hey-lady/shared/static/images/loader/page-loading-animation.svg";
import React from "react";

const PageLoaderAnimation: React.FC = () => {
  return (
    <object type="image/svg+xml" data={loadingAnimation}>
      Loading...
    </object>
  );
};

export default PageLoaderAnimation;
