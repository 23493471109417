import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  type Operation,
  fromPromise,
  split,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { Observable, getMainDefinition } from "@apollo/client/utilities";
import { type ErrorResponse, onError } from "@apollo/link-error";
import { isSubscriptions } from "@hey-lady/shared/helpers/utils";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { createClient } from "graphql-ws";
import React from "react";
import { userHostedEventsFieldsPolicy } from "./cache/hostedEventsCache";
import { notificationsFieldsPolicy } from "./cache/notificationsCache";

/**
 * Types
 */
interface Props {
  children: JSX.Element[] | JSX.Element;
}

/**
 * Helpers
 */
const PUBLIC_ROUTES = [/auth/, /signup/, /welcome/];
const logout = (): void => {
  if (
    !PUBLIC_ROUTES.some((route) => route.test(window.location.pathname)) &&
    !window.location.pathname.startsWith("/auth/login")
  ) {
    const redirectFromParams = new URLSearchParams(window.location.search);
    const pathname = window.location.pathname;
    const loginQueryParams = new URLSearchParams();
    loginQueryParams.append("redirectedFrom", `${pathname}?${redirectFromParams.toString()}`);

    window.location.href = `/auth/login?${loginQueryParams.toString()}`;
  } else if (window.location.pathname.startsWith("/signup/checkout")) {
    window.location.href = "/signup/step/1";
  }
};

/**
 * Links
 */
const ws = new GraphQLWsLink(
  createClient({
    shouldRetry() {
      return true;
    },
    url: import.meta.env.VITE_API_WSS_URL ?? "",
  }),
);

const http = createUploadLink({
  credentials: "include",
  uri: import.meta.env.VITE_API_URL,
  headers: { "Apollo-Require-Preflight": "true" },
});

const retryLink = new RetryLink();

let isRefreshing = false;
let pendingRequests: Array<() => void> = [];

const resolvePendingRequests = () => {
  pendingRequests.forEach((callback) => {
    callback();
  });
  pendingRequests = [];
};

function getNewToken() {
  return fetch(`${import.meta.env.VITE_OAUTH_URL}/refresh`, {
    method: "POST",
    credentials: "include",
  });
}

/**
 * Error handling
 *
 * Try to use refresh token
 * in case of error when users access token is expired
 * @link https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
 */
const error = onError(({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      if (error?.message === "Unauthorized") {
        // Try refreshing access
        // biome-ignore lint/suspicious/noConfusingVoidType: it is what it is
        let forward$: Observable<boolean | void>;

        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            getNewToken()
              .then((response) => {
                if (response.ok) {
                  resolvePendingRequests();
                  return true;
                }

                pendingRequests = [];
                // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                logout();
                return false;
              })
              .catch((error) => {
                console.error(error);
                pendingRequests = [];
                // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                logout();
              })
              .finally(() => {
                isRefreshing = false;
              }),
          ).filter((value) => Boolean(value));
        } else {
          // Will only emit once the Promise is resolved
          forward$ = fromPromise(
            new Promise<void>((resolve) => {
              pendingRequests.push(() => {
                resolve();
              });
            }),
          );
        }

        return forward$.flatMap(() => forward(operation));
      }
      console.warn(error);
    }
  }
  if (networkError) {
    console.error(networkError);
    // window.location.replace("/heavy-load");
  }
});

const transport = split(
  (operation: Operation) => isSubscriptions(getMainDefinition(operation.query)),
  ws,
  http as any,
);

/**
 * Client
 */
const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          ...notificationsFieldsPolicy,
          ...userHostedEventsFieldsPolicy,
        },
      },
    },
  }),
  connectToDevTools: import.meta.env.DEV,
  link: ApolloLink.from([retryLink, error, transport as any]),
});

const Apollo: React.FC<Props> = ({ children }: Props) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
