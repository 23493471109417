import { useState } from "react";
import { useErrorHandler } from "./errorHandler";

export const useUploadFile = (upload: (files: File[]) => Promise<void>) => {
  const [loading, setLoading] = useState(false);
  const { handleError } = useErrorHandler();

  const onDrop = async (files: File[]): Promise<void> => {
    setLoading(true);
    await handleError(upload, {
      finally: () => {
        setLoading(false);
      },
    })(files);
  };

  return {
    loading,
    onDrop,
  } as const;
};
