import { Spacing } from "@hey-lady/shared/helpers/enum";
import { useFormatMessages } from "@hey-lady/shared/hooks/general";
import worker from "@hey-lady/shared/static/images/worker@2x.png";
import Button from "@hey-lady/ui/atoms/Button";
import { Box, Typography } from "@mui/material";

export default function NewVersionFallback() {
  const reloadPage = () => {
    window.location.reload();
  };

  const [heading, description, reload] = useFormatMessages([
    {
      id: "ERROR_UPDATE_HEADING",
    },
    {
      id: "ERROR_UPDATE_DESCRIPTION",
      values: {
        br: <br />,
      },
    },
    {
      id: "ERROR_RELOAD",
    },
  ]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={Spacing.xl}
    >
      <Box>
        <img src={worker} alt="Worker illustration" height="400" />
      </Box>
      <Typography variant="h1">{heading}</Typography>
      <Box maxWidth={700}>
        <Typography variant="body2" align="center">
          {description}
        </Typography>
      </Box>
      <Box my={Spacing.ml}>
        <Button variant="contained" color="secondary" onClick={reloadPage}>
          {reload}
        </Button>
      </Box>
    </Box>
  );
}
