import { gql } from "@apollo/client";

export const ACTIVATE_USER = gql`
  mutation activateUser($input: ActivateUserInput!) {
    activateUser(input: $input) {
      status
    }
  }
`;

export const RESEND_CONFIRMATION_EMAIL = gql`
  mutation resendConfirmationEmail($input: ResendConfirmationEmailInput!) {
    resendConfirmationEmail(input: $input) {
      status
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

export const VERIFY_USER = gql`
  mutation verifyUser($id: String!, $input: VerifyUserInput!) {
    verifyUser(id: $id, input: $input) {
      status
    }
  }
`;

export const HEARTBEAT = gql`
  mutation heartbeat($input: UpdateUserInput!) {
    heartbeat(input: $input) {
      status
    }
  }
`;

export const UPDATE_ME = gql`
  mutation updateMe($input: UpdateUserInput!) {
    updateMe(input: $input) {
      id
      avatarUrl
      needSpeakingPartner
      otherLanguage
      cambridgeEnglishLevel
      firstName
      englishSkills
      interests
      occupation {
        id
      }
      isComplete
      lastName
      city
      country
      story
      birthday
      showBirthday
      nativeLanguage
      wantsNewsletter
      completedPercentage
      availability {
        from
        until
      }
    }
  }
`;

export const GENERATE_USER_UPLOAD_URL = gql`
  mutation generateUserUploadUrl($input: UploadPhotoInput!) {
    generateUserUploadUrl(input: $input) {
      contentURL
      uploadURL
    }
  }
`;

export const CREATE_USER_PHOTO = gql`
  mutation createUserPhoto($input: UpdatePhotoInput!) {
    createUserPhoto(input: $input) {
      id
      url
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_USER_PHOTO = gql`
  mutation deleteUserPhoto($input: DeletePhotoInput!) {
    deleteUserPhoto(input: $input) {
      id
    }
  }
`;

export const REQUEST_RESET_PASSWORD = gql`
  mutation requestResetPassword($input: RequestPasswordInput!) {
    requestResetPassword(input: $input) {
      status
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      status
    }
  }
`;

export const UPDATE_USER_SETTINGS = gql`
  mutation updateUserSettings($input: UpdateSettingsInput!) {
    updateUserSettings(input: $input) {
      status
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      status
    }
  }
`;

export const DEACTIVATE = gql`
  mutation deactivate($input: DeactivateInput!) {
    deactivate(input: $input) {
      reason
      status
    }
  }
`;

export const RECOVER_ACCOUNT = gql`
  mutation recoverAccount {
    recoverAccount {
      status
    }
  }
`;

export const REPORT_USER_ABUSE = gql`
  mutation createAbuse($input: ReportAbuseInput!) {
    createAbuse(input: $input) {
      reason
      description
    }
  }
`;

export const CONTACT_US = gql`
  mutation contactUs($input: ContactUsInput!) {
    contactUs(input: $input) {
      status
    }
  }
`;

export const ZOOM_ADD_LICENSE = gql`
  mutation addZoomLicenseToUser($userID: String!) {
    addZoomLicenseToUser(userID: $userID) {
      status
    }
  }
`;

export const FOLLOW = gql`
  mutation follow($input: FollowInput!) {
    follow(input: $input) {
      status
    }
  }
`;

export const UNFOLLOW = gql`
  mutation unFollow($input: FollowInput!) {
    unFollow(input: $input) {
      status
    }
  }
`;

export const PAUSE = gql`
  mutation pause {
    pause {
      status
    }
  }
`;

export const RESUME = gql`
  mutation resume {
    resume {
      status
    }
  }
`;

export const SIGNUP = gql`
  mutation subscribe($input: SubscribeInput!) {
    subscribe(input: $input)
  }
`;

export const SET_TOKEN = gql`
  mutation setToken($input: String!) {
    setToken(input: $input) {
      status
    }
  }
`;

export const MARK_REPORT_AS_SEEN = gql`
  mutation markReportAsSeen {
    markReportAsSeen {
      status
    }
  }
`;

export const COMPLETE_ONBOARDING_QUIZ = gql`
  mutation assignCompleteOnboardingQuizBadge {
    assignCompleteOnboardingQuizBadge {
      status
    }
  }
`;

export const SEEN_COMPLETED_BASICS = gql`
  mutation seenCompletedBasics {
    seenCompletedBasics {
      status
    }
  }
`;

export const SEND_LINK = gql`
  mutation sendLink($input: FriendInput!) {
    sendLink(input: $input) {
      status
    }
  }
`;
