import config from "./config";

export default {
  styleOverrides: {
    root: {
      color: config.colors.base.black,
      fontSize: config.typography.size.normal,
      lineHeight: config.typography.line.normal,
      paddingLeft: config.spacing.l,
      paddingRight: config.spacing.l,
      paddingTop: config.spacing.s,
      paddingBottom: config.spacing.s,
      "&:focus": {
        backgroundColor: "unset",
      },
      "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible, &:hover": {
        backgroundColor: "unset",
        color: config.colors.orange.brandy,
      },
    },
  },
};
