import { dec, inc, isEmpty, not } from "ramda";
import {
  type Dispatch,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useIntl } from "react-intl";

import {
  DefaultPageParam,
  MEMBERZINE_ROWS_PER_PAGE_DEFAULT,
  PAGE_DEFAULT,
} from "@hey-lady/shared/helpers/const";

import EventsFiltersContext from "@hey-lady/app/context/EventsFiltersContext";
import FiltersContext from "@hey-lady/app/context/FiltersContext";
import { useLocation, useNavigate, useParams } from "react-router";

/**
 * Types
 */
interface PaginatedData<T> {
  items?: T[] | null;
  total: number;
}

export const useFormatMessage = (
  id: string,
  values?: Record<string, string | number | boolean | Date | ReactNode>,
): ReactNode => {
  const intl = useIntl();

  return intl.formatMessage({ id }, values);
};

export const useFormatMessages = (
  messages: Array<{
    id: string;
    values?: Record<
      string,
      string | number | boolean | Date | ReactNode | ((s: string) => ReactNode)
    >;
  }>,
): ReactNode[] => {
  const intl = useIntl();

  // @ts-expect-error INTL types are just confusing
  return messages.map(({ id, values }) => intl.formatMessage({ id }, values));
};

export const useSetState = <T>(initialState: T): [T, Dispatch<T>] => {
  return useReducer((prevState: T, state: T) => ({ ...prevState, ...state }), initialState);
};

export const useSwitch = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const onSwitch = useCallback(() => {
    setState(not(state));
  }, [state]);

  return [state, onSwitch] as const;
};

export const useOnOffSwitch = (initialState = false) => {
  const [state, setState] = useSetState<{ open: boolean }>({
    open: initialState,
  });

  const onOn = useCallback((): void => {
    setState({
      open: true,
    });
  }, [setState]);

  const onOff = useCallback((): void => {
    setState({
      open: false,
    });
  }, [setState]);

  return [state.open, onOn, onOff] as const;
};

export const usePage = <T>(data?: PaginatedData<T>) => {
  const [page, setPage] = useState(PAGE_DEFAULT);
  const { filters } = useContext(FiltersContext);

  const onChangePage = useCallback((_: any, nextPage: number): void => {
    setPage(dec(nextPage));
  }, []);

  // TODO don't use useEffect, rather use just a callback function to update pagination when filters change
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to reset the page when filters change
  useEffect(() => {
    setPage(PAGE_DEFAULT);
  }, [filters]);

  return {
    items: data?.items,
    total: Math.ceil((data?.total ?? 0) / MEMBERZINE_ROWS_PER_PAGE_DEFAULT),
    page: inc(page),
    onChangePage,
  } as const;
};

export const usePageWithNavigate = <T>(data?: PaginatedData<T>) => {
  const navigate = useNavigate();
  const params = useParams();

  const page = params.page ? Number(params.page) : Number(DefaultPageParam);

  const location = useLocation();
  const isEventsAllPage = location.pathname.includes("events/all");
  const { searchParams } = useContext(EventsFiltersContext);
  const pageSearchParams = isEventsAllPage ? searchParams : "";

  const onChangePage = useCallback(
    (_: any, nextPage: number): void => {
      navigate({ pathname: `../${nextPage}`, search: pageSearchParams });
    },
    [navigate, pageSearchParams],
  );

  useEffect(() => {
    // redirect to the first page if page number in URL produces no results
    if (isEmpty(data?.items) && page > 1) {
      navigate({ pathname: `../${DefaultPageParam}`, search: pageSearchParams }, { replace: true });
    }
  }, [data?.items, page, pageSearchParams, navigate]);

  return {
    items: data?.items,
    total: Math.ceil((data?.total ?? 0) / MEMBERZINE_ROWS_PER_PAGE_DEFAULT),
    page,
    onChangePage,
  } as const;
};
