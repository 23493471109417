import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import PageLoaderAnimation from "./PageLoaderAnimation";

/**
 * Styles
 */

const useStyles = makeStyles()({
  root: {
    width: "150px",
    marginTop: "80px",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const PageLoader: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <PageLoaderAnimation />
    </Box>
  );
};

export default PageLoader;
