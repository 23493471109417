import config from "./config";

export default {
  styleOverrides: {
    root: {
      paddingLeft: "unset",
      paddingRight: "unset",
      paddingTop: config.spacing.m,
      paddingBottom: config.spacing.m,
      fontSize: config.typography.size.big,
      fontWeight: config.typography.weight.regular,
      lineHeight: config.typography.line.normal,
      "&.Mui-expanded": {
        minHeight: "auto",
      },
    },
    content: {
      display: "flex",
      marginTop: "unset",
      marginBottom: "unset",
      alignItems: "center",
      "&:before": {
        content: "'•'",
        marginRight: config.spacing.ml,
      },
      "&:after": {
        content: "'+'",
        marginLeft: "auto",
        color: config.colors.gray.boulder,
        fontSize: config.typography.size.subheading,
      },
      "&.Mui-expanded": {
        marginTop: "unset",
        marginBottom: "unset",
        "&:after": {
          content: "'—'",
        },
      },
    },
  },
};
