import config from "./config";

export default {
  styleOverrides: {
    root: {
      color: config.colors.base.black,
      fontSize: config.typography.size.normal,
      fontWeight: config.typography.weight.light,
      marginBottom: config.spacing.s,
      "&.Mui-focused": {
        color: config.colors.base.black,
      },
      "&.Mui-error": {
        color: config.colors.red.monza,
      },
    },
    formControl: {
      position: "unset" as const,
      zIndex: "unset",
    },
    shrink: {
      transform: "unset",
    },
    sizeSmall: {
      marginBottom: "unset",
    },
  },
  defaultProps: {
    shrink: true,
    focused: true,
    disableAnimation: true,
  },
};
