import config from "./config";

export default {
  styleOverrides: {
    padding: {
      paddingTop: config.spacing.s,
      paddingBottom: config.spacing.s,
    },
  },
};
