import config from "./config";

export default {
  styleOverrides: {
    root: {
      width: "50px",
      height: "28px",
      padding: "unset",
      borderRadius: config.radius.l,
      marginRight: config.spacing.sm,
    },
    thumb: {
      width: "20px",
      height: "20px",
      boxShadow: "unset",
      backgroundColor: config.colors.white,
    },
    track: {
      opacity: 1,
      backgroundColor: config.colors.gray.default,
    },
    colorPrimary: {
      "&.Mui-checked + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: config.colors.green.pea,
      },
    },
    switchBase: {
      padding: "4px",
      "&.Mui-checked": {
        transform: "translateX(20px)",
      },
    },
  },
  defaultProps: {
    disableRipple: true,
  },
};
