import config from "./config";

export default {
  styleOverrides: {
    root: {
      marginLeft: "initial",
      marginRight: "initial",
      marginBottom: "initial",
      color: config.colors.gray.default,
      marginTop: config.spacing.s,
      fontSize: config.typography.size.small,
      fontWeight: config.typography.weight.regular,
      lineHeight: config.typography.line.small,
      "&.Mui-error": {
        color: config.colors.red.monza,
      },
    },
    contained: {
      marginLeft: "initial",
      marginRight: "initial",
    },
  },
};
