import config from "./config";

export default {
  styleOverrides: {
    root: {
      height: "auto",
      maxHeight: "360px",

      "& .MuiPickersCalendarHeader-root": {
        marginBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
      "& .MuiPickersCalendarHeader-labelContainer": {
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
      },
      "& .MuiPickersCalendarHeader-label": {
        marginRight: 0,
        fontWeight: config.typography.weight.regular,
        fontSize: config.typography.size.normal,
      },
      "& .MuiPickersArrowSwitcher-root": {
        width: "100%",
        justifyContent: "space-between",
      },
      "& .MuiDayCalendar-weekDayLabel": {
        fontWeight: config.typography.weight.regular,
        color: config.colors.gray.default,
      },
      "& .MuiPickersYear-yearButton.Mui-selected, & .MuiPickersMonth-monthButton.Mui-selected": {
        backgroundColor: config.colors.orange.brandy,
        "&:hover, &:focus": {
          backgroundColor: config.colors.orange.brandy,
        },
      },
      "& .MuiPickersDay-root": {
        width: "40px",
        height: "40px",
        margin: 0,
        fontSize: config.typography.size.small,
        "&.Mui-selected, &:hover.Mui-selected, &:focus.Mui-selected": {
          backgroundColor: config.colors.orange.brandy,
        },
        "&:focus": {
          backgroundColor: config.colors.beige.light,
        },
      },
      "& .MuiPickersSlideTransition-root": {
        minHeight: "254px",
      },
    },
  },
};
