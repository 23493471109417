import { useSnackbar } from "notistack";
import { useCallback } from "react";

interface HandleErrorOptions<T> {
  onSuccess?: (data: T) => void;
  onError?: (error: Error) => void;
  finally?: () => void;
}

export function useErrorHandler() {
  const { enqueueSnackbar } = useSnackbar();

  const handleError = useCallback(
    function handler<T, Args extends any[]>(
      fun: (...args: Args) => Promise<T>,
      options?: HandleErrorOptions<T>,
    ) {
      return async (...args: Args) => {
        try {
          const result = await fun(...args);
          options?.onSuccess?.(result);
          return result;
        } catch (error) {
          enqueueSnackbar(error.message, { variant: "error" });
          options?.onError?.(error);
        } finally {
          options?.finally?.();
        }
      };
    },
    [enqueueSnackbar],
  );

  return { handleError };
}
