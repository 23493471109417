import "@hey-lady/shared/static/css/fonts.css";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import Root from "./core/Root";

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_HOST_ENVIRONMENT,
    ignoreErrors: [/ChunkLoadError/],
  });
}

const container = document.getElementById("root") as Element;
const root = createRoot(container);
root.render(<Root />);
