import { ReactNode } from "react";

export interface QuestionModel {
  answer: string;
  question: string;
}

export interface LinkModel {
  href: string;
  title: string;
}

export interface IdeaModel {
  idea: string;
}

export enum AttachmentKind {
  Wordlist = "WORD_LIST",
  Wordbook = "WORDBOOK",
}

export interface AttachmentModel {
  url?: string;
  size: number;
  name: string;
  extension: string;
}

export interface AttachmentValue {
  kind: AttachmentKind;
  attachment: AttachmentModel;
}

export interface PlaceModel {
  cityId?: string;
  countryId?: string;
  city?: string;
  country?: string;
  lat?: number;
  long?: number;
  location?: string;
  locationId?: string;
}

export interface SectionButton {
  buttonType: TopicButtonType;
  buttonAlignment: TopicButtonAlignment;
  buttonText?: string;
  buttonURL?: string;
}

export interface ImageWithUrl {
  fileUrl: string;
  url?: string;
  openInNewTab: boolean;
}

export interface ImageAndButton {
  buttonType: TopicButtonType;
  buttonAlignment: TopicButtonAlignment;
  buttonText?: string;
  buttonURL?: string;
  fileUrl?: string | ImageWithUrl;
}

export interface SectionBanner {
  bannerText?: string;
  bannerImageURL?: string;
  buttonText?: string;
  buttonURL?: string;
}

export enum TopicButtonAlignment {
  Left = "Left",
  Center = "Center",
  Right = "Right",
}

export enum TopicButtonType {
  Link = "Link",
  Video = "Pop-Up Video",
}

export enum GridColumns {
  TwoEqualColumns = "TwoEqualColumns",
  TreeEqualColumns = "TreeEqualColumns",
  OneThirdTwoThirds = "OneThirdTwoThirds",
  TwoThirdsOneThird = "TwoThirdsOneThird",
  OneFourthThreeFourths = "OneFourthThreeFourths",
  ThreeFourthsOneFourth = "ThreeFourthsOneFourth",
}

export interface AutocompleteOption {
  id: string;
  value?: string;
  meta?: any;
  name?: string | ReactNode;
  title?: string | ReactNode;
  image?: string;
  type?: string;
  groupName?: string;
}
