import config from "./config";

export default {
  styleOverrides: {
    root: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: config.colors.gray.smoke,
      backgroundColor: config.colors.white,
      borderRadius: config.radius.sm,
      "& fieldset": {
        border: "unset",
      },
      "&.Mui-error": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: config.colors.red.monza,
      },
    },
  },
};
