import { useApolloClient } from "@apollo/client";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";

export const LOGIN_STORAGE_KEY = "loggedInUser";

export function useAuthUserTracking() {
  // Watch changes to local storage, and if we logout in 1 tab,
  // logout in every other tab.
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  const syncLogout = useCallback(
    async (event: StorageEvent) => {
      if (event.key === LOGIN_STORAGE_KEY) {
        if (event.newValue !== event.oldValue) {
          await apolloClient.resetStore();
          navigate("/auth/login");
        }
      }
    },
    [apolloClient, navigate],
  );

  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return () => {
      window.removeEventListener("storage", syncLogout);
    };
  }, [syncLogout]);
}
