import config from "./config";

export default {
  styleOverrides: {
    paper: {
      margin: "4px 0",
      boxShadow: "0px 2px 10px rgb(0 0 0 / 0.15)",
    },
    listbox: {
      maxHeight: "40vh",
      paddingTop: config.spacing.ml,
      paddingBottom: config.spacing.ml,
      "& .MuiAutocomplete-option": {
        paddingTop: config.spacing.sm,
        paddingBottom: config.spacing.sm,
        paddingLeft: config.spacing.l,
        paddingRight: config.spacing.l,
        color: config.colors.gray.default,
        "&[aria-selected='true']": {
          backgroundColor: "unset",
        },
        "&:hover": {
          color: config.colors.base.black,
        },
      },
      "& .MuiAutocomplete-option.Mui-focused": {
        backgroundColor: "unset",
      },
      "& .MuiAutocomplete-option[aria-selected='true']": {
        backgroundColor: "unset",
      },
      "& .MuiAutocomplete-option[aria-selected='true'].Mui-focused": {
        backgroundColor: "unset",
      },
    },
    option: {
      paddingTop: config.spacing.sm,
      paddingBottom: config.spacing.sm,
      paddingLeft: config.spacing.l,
      paddingRight: config.spacing.l,
      color: config.colors.gray.default,
      "&[aria-selected='true']": {
        backgroundColor: "unset",
      },
      "&:hover": {
        color: config.colors.base.black,
      },
    },
    inputRoot: {
      "&.Mui-error": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: config.colors.red.monza,
      },
    },
  },
};
