import { Box, Container, type Theme, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

import worker from "@hey-lady/shared/static/images/worker@2x.png";

import { useFormatMessages } from "@hey-lady/shared/hooks/general";
import { useAuth } from "@hey-lady/shared/hooks/user";

import { Spacing } from "@hey-lady/shared/helpers/enum";

import Button from "@hey-lady/ui/atoms/Button";
import { useNavigate } from "react-router";

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    backgroundColor: theme.palette.background.default,
  },
  subtitle: {
    width: "560px",
  },
  image: {
    width: "270px",
    height: "auto",
    marginTop: "-100px",
    transform: "scaleX(-1)",
  },
}));

const Error404Page: React.FC = () => {
  const auth = useAuth();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [whoops, theLink, goToHome, contactUs] = useFormatMessages([
    { id: "NOT_FOUND_WHOOPS" },
    { id: "NOT_FOUND_THE_LINK" },
    { id: "NOT_FOUND_GO_TO_HOME" },
    { id: "NOT_FOUND_CONTACT_US" },
  ]);

  const onClick: () => void = () => {
    if (auth.authenticated) {
      navigate("/home");
    } else {
      navigate("/auth/login");
    }
  };

  return (
    <Box className={classes.root} display="flex" justifyContent="center" alignItems="center">
      <Container maxWidth="sm">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box m={Spacing.l}>
            <img src={worker} className={classes.image} alt="worker illustration" />
          </Box>
          <Box m={Spacing.s}>
            <Typography variant="h2" align="center">
              {whoops}
            </Typography>
          </Box>
          <Box my={Spacing.s} className={classes.subtitle}>
            <Typography variant="h6" align="center">
              {theLink}
            </Typography>
          </Box>
          <Box m={Spacing.l}>
            <Button variant="contained" onClick={onClick}>
              {goToHome}
            </Button>
          </Box>
          <Box>
            <a href="mailto:hello@heylady.io" target="_blank" rel="noreferrer">
              <Typography variant="caption" color="orange.brandy" component="span">
                {contactUs}
              </Typography>
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Error404Page;
