import { Box, Container, type Theme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import worker from "@hey-lady/shared/static/images/worker@2x.png";

import { Spacing } from "@hey-lady/shared/helpers/enum";
import { useFormatMessages } from "@hey-lady/shared/hooks/general";

import Button from "@hey-lady/ui/atoms/Button";
import Typography from "@hey-lady/ui/atoms/Typography";

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    backgroundColor: theme.palette.background.default,
  },
  image: {
    width: "270px",
    height: "auto",
  },
}));

const HeavyLoadPage: React.FC = () => {
  const { classes } = useStyles();

  const [oops, weAreWorkingOnIt, tryAgain] = useFormatMessages([
    { id: "HEAVY_LOAD_PAGE_OOPS" },
    { id: "HEAVY_LOAD_PAGE_WE_RE_WORKING_ON_IT" },
    { id: "HEAVY_LOAD_PAGE_TRY_AGAIN" },
  ]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" className={classes.root}>
      <Container maxWidth="sm">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box m={Spacing.l}>
            <img src={worker} className={classes.image} alt="heavy load worker" />
          </Box>

          <Box m={Spacing.s}>
            <Typography variant="h2" align="center">
              {oops}
            </Typography>
          </Box>

          <Box my={Spacing.s}>
            <Typography variant="h6" align="center">
              {weAreWorkingOnIt}
            </Typography>
          </Box>

          <Box m={Spacing.l}>
            <Link to="/">
              <Button variant="contained">{tryAgain}</Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeavyLoadPage;
