import config from "./config";

export default {
  styleOverrides: {
    root: {
      boxShadow: "unset",
      borderBottom: `1px solid ${config.colors.gray.smoke}`,
      "&.Mui-expanded": {
        marginTop: "unset",
        marginBottom: "unset",
      },
      "&:before": {
        height: "unset",
        background: "unset",
      },
    },
  },
  defaultProps: {
    square: true,
  },
};
