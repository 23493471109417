import React from "react";

import SvgIcon, { type Props } from "@hey-lady/ui/atoms/SvgIcon";

const IconCross: React.FC<Props> = (props: Props) => {
  return (
    <SvgIcon {...props}>
      <path d="M13.1 12l7.5-7.5c.3-.3.3-.8 0-1.1-.3-.3-.8-.3-1.1 0L12 10.9 4.5 3.5c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l7.5 7.5-7.5 7.5c-.3.3-.3.8 0 1.1.3.3.8.3 1.1 0l7.5-7.5 7.5 7.5c.3.3.8.3 1.1 0 .3-.3.3-.8 0-1.1L13.1 12z" />
    </SvgIcon>
  );
};

export default IconCross;
