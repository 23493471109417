import { Spacing } from "@hey-lady/shared/helpers/enum";
import { Box } from "@mui/material";
import React from "react";

const LogoBranding: React.FC = () => {
  return (
    <Box component="span" fontStyle="italic" display="inline" mr={Spacing.xs}>
      Hey Lady!
    </Box>
  );
};

export default LogoBranding;
