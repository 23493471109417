import config from "./config";

export default {
  styleOverrides: {
    rounded: {
      borderRadius: config.radius.sm,
    },
    elevation1: {
      boxShadow: config.elevation.xs,
    },
    elevation8: {
      boxShadow: config.elevation.s,
    },
  },
  defaultProps: {
    elevation: 1,
  },
};
