import { Spacing } from "@hey-lady/shared/helpers/enum";
import { useFormatMessages } from "@hey-lady/shared/hooks/general";
import worker from "@hey-lady/shared/static/images/worker@2x.png";
import Button from "@hey-lady/ui/atoms/Button";
import Typography from "@hey-lady/ui/atoms/Typography";
import { Box } from "@mui/material";
import type { FallbackRender } from "@sentry/react";
import { Link } from "react-router-dom";
import NewVersionFallback from "./NewVersionFallback";

export const RouterErrorFallback: FallbackRender = ({ error }) => {
  if ((error as Error).name.includes("ChunkLoadError")) {
    return <NewVersionFallback />;
  }
  return <FallbackComponent />;
};

export const FallbackComponent = () => {
  const reloadPage = () => {
    window.location.reload();
  };

  const [heading, description, reload, goHome, patience] = useFormatMessages([
    {
      id: "ERROR_HEADING",
    },
    {
      id: "ERROR_DESCRIPTION",
      values: {
        contact: (str) => <a href="mailto:hello@heylady.io">{str}</a>,
      },
    },
    {
      id: "ERROR_RELOAD",
    },
    {
      id: "ERROR_GO_HOME",
    },
    {
      id: "ERROR_PATIENCE",
    },
  ]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={Spacing.xl}
    >
      <Box>
        <img src={worker} alt="Worker illustration" height="400" />
      </Box>
      <Typography variant="h1">{heading}</Typography>
      <Box maxWidth={700}>
        <Typography variant="body2">{description}</Typography>
      </Box>
      <Box my={Spacing.ml}>
        <Button variant="contained" color="secondary" onClick={reloadPage}>
          {reload}
        </Button>
        <Link to="/home">
          <Button variant="contained">{goHome}</Button>
        </Link>
      </Box>
      <Typography variant="body2">{patience}</Typography>
    </Box>
  );
};
