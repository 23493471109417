import config from "./config";

export default {
  styleOverrides: {
    adornedStart: {
      paddingLeft: config.spacing.ml,
    },
    adornedEnd: {
      paddingRight: config.spacing.ml,
    },
    multiline: {
      paddingTop: "unset",
      paddingBottom: "unset",
    },
  },
};
