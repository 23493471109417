import config from "./config";

export default {
  styleOverrides: {
    root: {
      height: "1px",
      paddingTop: "6px",
      paddingBottom: "6px",
      color: config.colors.gray.default,
    },
    track: {
      width: "0 !important",
    },
    rail: {
      height: "1px",
      opacity: "unset",
    },
    thumb: {
      width: "29px",
      height: "29px",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: config.colors.base.black,
      color: config.colors.white,
      "&:focus, &:hover": {
        boxShadow: "0px 0px 0px 7px rgb(187 187 187 / 0.2)",
      },
      "&.Mui-active, &.Mui-focusVisible:hover": {
        boxShadow: "0px 0px 0px 14px rgb(187 187 187 / 0.2)",
      },
    },
    marked: {
      marginBottom: config.spacing.xxl,
    },
    mark: {
      width: "13px",
      height: "13px",
      borderRadius: config.radius.l,
      backgroundColor: config.colors.base.black,
    },
    markActive: {
      opacity: "unset",
      backgroundColor: config.colors.base.black,
    },
    markLabel: {
      top: config.spacing.xl,
      color: config.colors.base.black,
      fontSize: config.typography.size.big,
      fontWeight: config.typography.weight.light,
      lineHeight: config.typography.line.normal,
      transform: `translateX(-${config.spacing.s}px)`,
    },
    markLabelActive: {
      color: config.colors.base.black,
    },
  },
};
