export const MAX_ACCEPTED_IMG_WIDTH = 1024;

export const getImageDimensions = async (
  file: File,
): Promise<{ width: number; height: number; naturalWidth: number; naturalHeight: number }> => {
  return await new Promise((resolve, reject) => {
    const src = window.URL.createObjectURL(file);
    const image = new Image();

    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height,
        naturalWidth: image.naturalWidth,
        naturalHeight: image.naturalHeight,
      });
    };

    image.src = src;
    image.onabort = reject;
  });
};

export const resizeImage = (image: any) => {
  const ratio = image.height / image.width;

  if (image.width > MAX_ACCEPTED_IMG_WIDTH) {
    return {
      width: MAX_ACCEPTED_IMG_WIDTH,
      height: Number.parseInt((ratio * MAX_ACCEPTED_IMG_WIDTH).toFixed()),
    };
  }
  return {
    width: Number.parseInt(image.width.toFixed()),
    height: Number.parseInt(image.height.toFixed()),
  };
};
