export default {
  styleOverrides: {
    root: {
      width: "100%",
    },
  },
  defaultProps: {
    hiddenLabel: false,
  },
};
