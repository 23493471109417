import axios, { type AxiosResponse } from "axios";

export const upload = async (url: string, file: File): Promise<AxiosResponse<any>> => {
  return await axios(url, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
      "Content-type": "application/octet-stream",
    },
    data: file,
  });
};

export const staticFileUrl = (fileUrl: string | null | undefined): string | undefined => {
  if (fileUrl?.startsWith("http")) {
    return encodeURI(fileUrl);
  }
  if (fileUrl && import.meta.env.VITE_STATIC_URL) {
    return encodeURI(`${import.meta.env.VITE_STATIC_URL}/${fileUrl}`);
  }
  return undefined;
};
