export default {
  styleOverrides: {
    root: {
      marginLeft: "unset",
      marginRight: "unset",
    },
    labelPlacementStart: {
      marginLeft: "unset",
      marginRight: "unset",
      justifyContent: "space-between",
    },
  },
};
