import { useAuthUserTracking } from "@hey-lady/shared/hooks/useAuthUserTracking";
import React, { type ReactNode } from "react";

interface Props {
  children: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
}
const AuthUserTrackingWrapper: React.FC<Props> = ({ children }: Props) => {
  useAuthUserTracking();

  return children;
};

export default AuthUserTrackingWrapper;
