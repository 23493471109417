import config from "./config";

export default {
  styleOverrides: {
    root: {
      marginTop: config.spacing.ml,
      "& .MuiMultiSectionDigitalClockSection-item.Mui-selected": {
        "&:hover": {
          color: config.colors.white,
        },
      },
    },
  },
};
