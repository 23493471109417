import { Typography as MuiTypography, type TypographyProps } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

import config from "@hey-lady/ui/overrides/config";

import { type FontWeight, type LineHeight } from "@hey-lady/shared/helpers/enum";

/**
 * Types
 */
export interface Props extends TypographyProps {
  weight?: FontWeight;
  line?: LineHeight;
  component?: React.ElementType;
  htmlColor?: string;
  fullWidth?: boolean;
}

/**
 * Styles
 */
const useStyles = makeStyles<{ params: Partial<Props> }>()((_theme, { params }) => ({
  root: {
    color: params.htmlColor,
    width: params.fullWidth ? "100%" : undefined,
  },
  fontWeight: {
    fontWeight: params.weight ? config.typography.weight[params.weight] : undefined,
  },
  lineHeight: {
    lineHeight: params.line ? config.typography.line[params.line] : undefined,
  },
}));

const Typography: React.FC<Props> = ({
  weight,
  line,
  htmlColor,
  className,
  fullWidth,
  ...props
}: Props) => {
  const params = { line, weight, htmlColor, fullWidth };
  const { classes, cx } = useStyles({ params });

  return (
    <MuiTypography
      {...props}
      className={cx([classes.root, classes.fontWeight, classes.lineHeight, className && className])}
    />
  );
};

export default Typography;
