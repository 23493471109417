import config from "./config";

export default {
  styleOverrides: {
    root: {
      paddingTop: "unset",
      paddingLeft: "unset",
      paddingRight: "unset",
      paddingBottom: config.spacing.ml,
      fontSize: config.typography.size.normal,
      fontWeight: config.typography.weight.light,
      lineHeight: config.typography.line.normal,
    },
  },
};
