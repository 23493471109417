import config from "./config";

export default {
  styleOverrides: {
    root: {
      height: "auto",
      paddingLeft: config.spacing.s,
      paddingRight: config.spacing.s,
      borderRadius: config.radius.l,
    },
    label: {
      paddingTop: config.spacing.sm,
      paddingBottom: config.spacing.sm,
      lineHeight: config.typography.line.small,
      fontSize: config.typography.size.small,
    },
    filled: {
      color: config.colors.white,
      backgroundColor: config.colors.orange.brandy,
      "&.MuiChip-clickable:hover": {
        backgroundColor: config.colors.orange.tumbleweed,
      },
    },
    filledPrimary: {
      color: config.colors.white,
      backgroundColor: config.colors.green.pea,
      "&.MuiChip-clickable:hover": {
        backgroundColor: config.colors.green.everglade,
      },
    },
    outlined: {
      color: config.colors.base.black,
      backgroundColor: config.colors.white,
      borderColor: config.colors.beige.almond,
      "&.MuiChip-clickable:hover": {
        backgroundColor: config.colors.beige.light,
      },
    },
    outlinedPrimary: {
      backgroundColor: config.colors.white,
      color: config.colors.green.pea,
      border: "none",
    },
    clickable: {
      "&:active": {
        boxShadow: "unset",
      },
    },
    deleteIconColorPrimary: {
      color: config.colors.white,
      "&:hover": {
        color: config.colors.white,
      },
    },
    deleteIcon: {
      margin: "unset",
    },
  },
};
