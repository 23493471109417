export enum PageName {
  Login = "login",
  SignUp = "sign-up",
  Redeem = "redeem",
  ForgotPassword = "forgot-password",
  CreatePassword = "create-password",
  TermsAndConditions = "terms-and-conditions",
}

export enum FaqTopic {
  OurCommunity = "our-community",
  MyAccount = "my-account",
  Meeting = "meeting",
  TopicsAndLessons = "topic-and-lessons",
  Events = "events",
  PlatformLaunch = "platform-launch",
  Subscriptions = "subscriptions",
}

export enum GridSize {
  OneSixth = 2,
  OneFourth = 3,
  OneThird = 4,
  FiveTwelves = 5,
  Half = 6,
  OneSeventh = 7,
  TwoThirds = 8,
  ThreeFourths = 9,
  FiveSixth = 10,
  Full = 12,
}

export enum Spacing {
  xs = 0,
  s = 1,
  sm = 2,
  m = 3,
  ml = 4,
  l = 5,
  xl = 6,
  xxl = 7,
  xxxl = 8,
}

export enum Elevation {
  xs = 1,
  s = 8,
}

export enum LineHeight {
  Title = "title",
  Subtitle = "subtitle",
  Heading = "heading",
  Subheading = "subheading",
  Big = "big",
  Paragraph = "paragraph",
  Normal = "normal",
  Small = "small",
  Initial = "initial",
}

export enum TourPage {
  HomePage = "home-page",
  EventsPage = "events-page",
  EventPage = "event-page",
  UsersPage = "users-page",
  UserPage = "user-page",
  TopicsPage = "topics-page",
  MeetingPage = "meeting-page",
}

export enum FontWeight {
  Light = "light",
  Regular = "regular",
  Semibold = "semibold",
  Bold = "bold",
}

export enum EventHostKind {
  Self = "SELF",
  CoHost = "COHOST",
  SomebodyElse = "SOMEBODY_ELSE",
}

export enum EventCapacityKind {
  UnLimited = "unlimited",
  Limited = "limited",
}

export enum EventStep {
  Details = "details",
  Time = "time",
  Participants = "participants",
  Materials = "materials",
  Cover = "cover",
  Confirmation = "confirmation",
  Kind = "kind",
}

export enum IdeaKind {
  Original = "ORIGINAL",
  Suggested = "SUGGESTED",
}

export enum MembersPaymentStep {
  CancelMembership = "CancelMembership",
  RequestRefund = "RequestRefund",
  RefundFeedbackForm = "RefundFeedbackForm",
  CancelFeedbackForm = "CancelFeedbackForm",
  SentRequest = "SentRequest",
  PauseMembership = "PauseMembership",
  MembershipIsPaused = "MembershipIsPaused",
  SwitchPlan = "SwitchPlan",
  RenewMembership = "RenewMembership",
  ReactivatedMembership = "ReactivatedMembership",
  RemoveCancelMembership = "RemoveCancelMembership",
  DeleteAccount = "DeleteAccount",
  AssistanceRequested = "AssistanceRequested",
}

export enum GoToEventSteps {
  CancelParticipation = "CancelParticipation",
  PreparingForEvent = "PreparingForEvent",
  EnglishLevelWarning = "EnglishLevelWarning",
  ConfirmAttendance = "ConfirmAttendance",
  AddToCalendar = "AddToCalendar",
  EventAddedSuccessfully = "EventAddedSuccessfully",
  ConfirmInPersonEvent = "ConfirmInPersonEvent",
  UpgradeToAttend = "UpgradeToAttend",
  ActivateToAttend = "ActivateToAttend",
}

export enum UserFields {
  name = "name",
  englishLevel = "englishLevel",
  location = "location",
  interests = "interests",
  englishSkills = "englishSkills",
  story = "story",
  occupation = "occupation",
  birthday = "birthday",
}
