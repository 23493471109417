import config from "./config";

export default {
  styleOverrides: {
    root: {
      paddingTop: config.spacing.sm,
      paddingBottom: config.spacing.sm,
    },
  },
};
