import config from "./config";

export default {
  styleOverrides: {
    root: {
      boxShadow: "unset",
      paddingTop: config.spacing.m,
      paddingBottom: config.spacing.m,
      paddingLeft: config.spacing.xl,
      paddingRight: config.spacing.xl,
    },
    colorPrimary: {
      backgroundColor: config.colors.white,
    },
  },
};
