export default {
  styleOverrides: {
    root: {
      ".MuiOutlinedInput-input": {
        padding: "18.5px 14px",
      },
    },
    notchedOutline: {
      border: "none",
    },
  },
};
