import { type FieldPolicy } from "@apollo/client";
import { concat, isEmpty, not, partition, prop, uniqBy } from "ramda";

const defaultResult = {
  total: 0,
  items: [],
};

// THINK this could've been a default policy for any paginated fields
export const notificationsFieldsPolicy: Record<string, FieldPolicy<any>> = {
  listNotificationsForUser: {
    keyArgs: ["unreadOnly"],
    merge: (existing, incoming, { args, readField }) => {
      let merged = existing ?? defaultResult;

      if (incoming) {
        // THINK is there a different way to know that this came from subscription?
        // TODO now we cannot be sure becouse what if on the last page user has only one notification
        let itemResult: any[];
        let total: number;
        const isWebsocketUpdate = incoming.items.length === 1;
        if (isWebsocketUpdate) {
          itemResult = concat(incoming.items, merged.items) as unknown as any[];
          total = incoming.total;
          if (args?.unreadOnly) {
            // We need to bump total by the number of already read items back so read function can accuratly remove them
            const readUnreadNotificationsCount =
              merged.items?.filter((item: any) => {
                const seen = readField("notificationSeen", item);
                return not(isEmpty(seen));
              }).length ?? 0;

            total += readUnreadNotificationsCount;
          }
        } else {
          itemResult = concat(merged.items, incoming.items) as unknown as any[];
          total = incoming.total;
        }
        merged = {
          ...incoming,
          total,
          // THINK Do we need to sort?
          items: uniqBy(prop("__ref"), itemResult),
        };
      }
      return merged;
    },
    read: (existing, { args, readField }) => {
      if (!existing) return existing;
      if (args?.unreadOnly) {
        const [unreadItems, readItems] = partition<any>((item) => {
          const seen = readField("notificationSeen", item);
          return isEmpty(seen);
        }, existing.items);
        const total = existing.total - readItems.length;
        return { ...existing, total, items: unreadItems };
      }

      return existing;
    },
  },
};
