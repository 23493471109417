import { format } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";

/**
 * Timezone fix for broken timezones on MacOS 14.0 (both in Chrome 116+ and Firefox)
 * Return timezone name based on the timezone offset.
 * For example, for offset -480 timezone will be Etc/GMT-8
 * which is a valid timezone:
 * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 *
 * For timezones that are fractional hours, e.g., UTC+10:30, on Mac,
 * get timezone name from the list of unique fractional timezones
 *
 * For other OS return Intl's timezone.
 */
export function getTimezone() {
  const macOS = navigator.userAgent.toLowerCase().includes("mac");

  if (!macOS) {
    return Intl.DateTimeFormat().resolvedOptions()?.timeZone || "UTC";
  }

  const timezoneOffset = new Date().getTimezoneOffset();

  if (timezoneOffset % 60 !== 0) {
    const fractionalTZ = fractionalTZName().get(timezoneOffset);
    if (fractionalTZ) {
      return fractionalTZ;
    }
    return Intl.DateTimeFormat().resolvedOptions()?.timeZone || "UTC";
  }

  const offsetHours = Math.floor(timezoneOffset / 60);
  if (offsetHours > 0) {
    return `Etc/GMT+${Math.abs(offsetHours)}`;
  }

  if (offsetHours < 0) {
    return `Etc/GMT-${Math.abs(offsetHours)}`;
  }

  return "UTC";
}

const uniqueFractionalTimezones = [
  "America/St_Johns",
  "Asia/Kabul",
  "Asia/Katmandu",
  "Asia/Kolkata",
  "Asia/Yangon",
  "Australia/Eucla",
  "Australia/North",
  "Australia/Yancowinna",
  "Canada/Newfoundland",
  "Iran",
  "Pacific/Chatham",
  "Pacific/Marquesas",
];

let tzOffsetToMap: Map<number, string> | null = null;
const fractionalTZName = () => {
  if (tzOffsetToMap === null) {
    tzOffsetToMap = new Map(
      uniqueFractionalTimezones.map((tzName) => [-getTimezoneOffset(tzName) / 60_000, tzName]),
    );
  }
  return tzOffsetToMap;
};

const timeZoneOffset = (currentTimezone: string) => {
  const offsetInMinutes = getTimezoneOffset(currentTimezone) / 1000 / 60;
  const sign = offsetInMinutes > 0 ? "+" : "-";
  const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
  const differenceInMinutes = Math.abs(offsetInMinutes) - hours * 60;
  const minutes = differenceInMinutes > 0 ? differenceInMinutes : 0;
  const separator = ":";
  return minutes > 0 ? `(UTC${sign}${hours}${separator}${minutes})` : `(UTC${sign}${hours})`;
};

export const formatTimeWithOffset = (date: Date, formatType: string) => {
  const currentTimezone = getTimezone();

  return `${format(date, formatType)} ${timeZoneOffset(currentTimezone)}`;
};
