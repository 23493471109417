import config from "./config";

export default {
  styleOverrides: {
    root: {
      border: `1px solid ${config.colors.gray.smoke}`,
      padding: `${config.spacing.sm}px ${config.spacing.m}px`,
    },
    stickyHeader: {
      border: "unset",
      borderBottom: `1px solid ${config.colors.gray.smoke}`,
      backgroundColor: config.colors.white,
    },
  },
};
